<template>
	<div style="padding: 20px">
		<div style="display: flex;">
			<div style="padding: 20px;">总收益：<span class="text-lg text-orange">{{total_profit}}</span>元</div>
			<div style="padding: 20px;">总售卡：<span class="text-lg text-orange">{{total_buy_count}}</span>张</div>
			<div style="padding: 20px;">总推广：<span class="text-lg text-orange">{{total_extension_user_num}}</span>人</div>
			<div style="padding: 20px;">推广金额：<span class="text-lg text-orange">{{total_extension_money}}</span>元</div>
		</div>
		<div class="flex justify-between">
			<el-form :inline="true" :model="searchForm">
				<el-form-item label="查询">
					<el-input v-model="searchForm.name" placeholder="请输入卡名"></el-input>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" icon="el-icon-search" @click="search()">查询</el-button>

				</el-form-item>
			</el-form>
			<!-- 操作按钮 -->
			<el-form :inline="true">
				<el-form-item>
					<!-- <el-button type="primary" @click="dialogFormVisible = true">会员卡采购</el-button> -->
					<!-- 	<el-button>导出</el-button> -->
					<!-- <el-button type="primary" icon="el-icon-edit" circle></el-button> -->
				</el-form-item>
			</el-form>
		</div>
		<el-table :data="tableData" v-loading="loading">
			<el-table-column type="index" width="50"></el-table-column>
			<el-table-column prop="name" label="权益卡名称"></el-table-column>
			<el-table-column prop="profit" label="收益"></el-table-column>
			<el-table-column prop="buy_count" label="售卡"></el-table-column>
			<el-table-column prop="extension_user_num" label="推广人数"></el-table-column>
			<el-table-column prop="extension_money" label="推广金额"></el-table-column>

			<el-table-column label="操作">
				<template slot-scope="scope">
					<el-button type="primary" @click="toViewRecords(scope.row)">查看记录</el-button>
				</template>
			</el-table-column>

		</el-table>
		<div class="text-center margin-top">
			<el-pagination @current-change="pageChange" :current-page="searchForm.page" :page-sizes="pageSizes"
				:page-size="searchForm.page_size" layout="total, sizes, prev, pager, next, jumper" :total="total"
				@size-change="pageSizeChange">
			</el-pagination>
		</div>



	</div>
</template>
<script>
	export default {
		data() {
			return {
				loading: false, // 表格加载状态
				tableData: [], // 数据
				pageSizes: [],
				searchForm: {
					name: '', // 否 string 用于搜索的关键字
					page: 1, // 否 int 页码
					page_size: 10, // 否 int 页脚
				},
				total: 0,
				// 统计
				total_buy_count:0,
				//推广人数
				total_extension_user_num:0,
				//总收益
				total_profit:0,
				//推广金额
				total_extension_money:0
				// total_buy_count: "5"
				// total_extension_money: "5"
				// total_extension_user_num: "5"
				// total_profit: "5
				

			};
		},
		mounted() {
			// 查询数据
			this.search()

		},
		methods: {
			search() {
				this.loading = true;
				this.$api.system.stat(this.searchForm).then(res => {
					console.log(res)
					this.loading = false;
					this.tableData = res.data.data;
					//总售卡
					this.total_buy_count=res.data.total_buy_count
					//总推广
					this.total_extension_user_num=res.data.total_extension_user_num
					//总收益
					this.total_profit=res.data.total_profit
					//推广金额
					this.total_extension_money=res.data.total_extension_money
					
					this.total = res.data.total;
					this.pageSizes = res.data.per_page.toString().split()
					this.searchForm.page_size = res.data.per_page
					
					
				})
			},
			pageSizeChange(size) {
				this.searchForm.page_size = size;
				this.search()
			},
			pageChange(page) {
				this.searchForm.page = page;
				this.search()
			},
			toViewRecords(item){
				 this.$router.push(`/memberRecharge/viewRecords/${item.id}`);
			},

		},

	};
</script>

<style scoped lang="scss">
	.cover {
		width: 40px;
		height: 40px;
		border-radius: 5px;
		overflow: hidden;
		margin-right: 10px;
	}

	/* 信息列表样式 */
	.msg-box>li {
		list-style: none;
		border-bottom: 1px solid #c5c5c5;
		padding: 20px 10px;
	}
</style>
